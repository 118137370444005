import Advert from "Pages/Advert";
import Home from "Pages/Home";
import { mqtt, mqttClient } from "precompile";
import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router";
import "./App.css";

const mqttTopic = "robot/test";

function App() {
  const [stateMqttClient, setMqttClient] = useState<any>();

  useEffect(() => {
    const client = mqtt.connect(mqttClient);
    console.log(client);
    setMqttClient(client);
  }, []);

  useEffect(() => {
    if (!!stateMqttClient) {
      stateMqttClient.on("connect", () => {
        console.log("CONNECTED");
      });
    }
  }, [stateMqttClient]);

  const callLift = () => {
    if (!!stateMqttClient) {
      console.log("CALLED");
      stateMqttClient.publish(mqttTopic, "open");
    }
  };

  return (
    <div className="App borderBox flex-row-start">
      {/* <button onClick={callLift}>LIFT</button> */}
      <Routes>
        <Route path="home" element={<Home />} />
        <Route path="advertisement" element={<Advert />} />
        <Route path="*" element={<Navigate to="home" />} />
      </Routes>
    </div>
  );
}

export default App;
