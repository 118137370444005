import { LeftArrow } from "Components/IconsOnly";
import Banner from "media/banner.png";
import HKHS from "media/HKHousingSociety.png";
import React from "react";
import { useNavigate } from "react-router";

function Advert() {
  const navigate = useNavigate();
  return (
    <section className="full-size flex-center">
      <div
        className="absolute z1 pointer flex-center round"
        style={{ top: 8, left: 8, background: "#333", height: "48px", width: "48px" }}
        onClick={() => navigate("home")}
      >
        <LeftArrow />
      </div>
      <div className="flex1 full-size">
        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/Nb1wcgC1Zjs" allow="autoplay"></iframe>
      </div>
      <div className="flex1 full-size flex-column-between p-1 borderBox" style={{ background: "white" }}>
        <div className="flex1 full-width flex-center borderBox">
          <img src={HKHS} alt={HKHS} style={{ maxWidth: "70%", maxHeight: "100%" }} />
        </div>
        <div className="flex1 full-width flex-center mt-4 borderBox">
          <img src={Banner} alt={Banner} style={{ maxWidth: "100%" }} />
        </div>
        <div className="flex1" />
      </div>
    </section>
  );
}

export default Advert;
