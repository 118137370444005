import { IWeatherForecast } from "variables";
import { HEADER_HEIGHT, iconURL, iconType } from "./headerVariables";

interface TextRowLayoutProps {
  children: JSX.Element;
}

export const DivRowLayout = (props: TextRowLayoutProps) => {
  return (
    <div style={{ scrollSnapAlign: "start" }}>
      <div className="flex-center" style={{ height: HEADER_HEIGHT }}>
        {props.children}
      </div>
    </div>
  );
};
export const TextRowLayout = (props: TextRowLayoutProps) => {
  return (
    <div style={{ scrollSnapAlign: "start" }}>
      <h6 className="flex-center" style={{ height: HEADER_HEIGHT }}>
        {props.children}
      </h6>
    </div>
  );
};

interface TemperatureProps {
  max: number;
  min: number;
  recordTime: string;
  iconNumber: number;
}

export const Temperatures = (props: TemperatureProps) => {
  return (
    <TextRowLayout>
      <>
        <h6>
          Today's weather：
          {props.min + " "}
          <sup>o</sup>C ~ {props.max + " "}
          <sup>o</sup>C
        </h6>
        <img
          className="mx-2"
          src={`${iconURL}${props.iconNumber}${iconType}`}
          alt={props.iconNumber.toString()}
          style={{ maxHeight: "60px", objectFit: "contain" }}
        />
        <h6>
          (Updated at　
          {new Date(props.recordTime).getHours() +
            ":" +
            new Date(props.recordTime).getMinutes().toString().padStart(2, "0")}
          )
        </h6>
      </>
    </TextRowLayout>
  );
};

export const WeatherForecast = ({
  forecasts,
  isBegin = false,
}: {
  forecasts: IWeatherForecast[];
  isBegin?: boolean;
}) => {
  return (
    <DivRowLayout>
      <div className="flex-row-start">
        {isBegin && <h6 className="mx-2">7-days weather forecast：</h6>}
        {forecasts.map((i) => {
          return (
            <span key={i.forecastDate} className="mx-2 flex-center">
              <div>
                <div>{i.week}</div>
                <div>
                  {i.forecastMintemp.value}
                  <sup>o</sup>C ~ {i.forecastMaxtemp.value}
                  <sup>o</sup>C
                </div>
              </div>
              <img
                className="mx-1"
                src={`${iconURL}${i.ForecastIcon}${iconType}`}
                alt={i.ForecastIcon.toString()}
                style={{ maxHeight: "60px", objectFit: "contain" }}
              />
            </span>
          );
        })}
      </div>
    </DivRowLayout>
  );
};
