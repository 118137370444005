export const displayDate = () => {
  const day = getDay(new Date().getDay());
  const date = new Date().getDate();
  const month = getMonth(new Date().getMonth());
  // const month = new Date().getMonth() + 1;
  const year = new Date().getFullYear();
  const second = new Date().getSeconds().toString().padStart(2, "0");
  const minute = new Date().getMinutes().toString().padStart(2, "0");
  const hour = new Date().getHours().toString().padStart(2, "0");
  return `${year} ${month} ${date}　${day}　${hour}:${minute}:${second}`;
  // return `${year}年${month}月${date}日　星期${day}　${hour}:${minute}:${second}`;
};

const getDay = (day: number) => {
  switch (day) {
    case 0:
      return "Sunday";
    case 1:
      return "Monday";
    case 2:
      return "Tuesday";
    case 3:
      return "Wednesday";
    case 4:
      return "Thursday";
    case 5:
      return "Friday";
    case 6:
      return "Saturday";
    // case 0:
    //   return "日";
    // case 1:
    //   return "一";
    // case 2:
    //   return "二";
    // case 3:
    //   return "三";
    // case 4:
    //   return "四";
    // case 5:
    //   return "五";
    // case 6:
    //   return "六";
  }
};

const getMonth = (month: number) => {
  switch (month) {
    case 0:
      return "Jan";
    case 1:
      return "Feb";
    case 2:
      return "Mar";
    case 3:
      return "Apr";
    case 4:
      return "May";
    case 5:
      return "Jun";
    case 6:
      return "Jul";
    case 7:
      return "Aug";
    case 8:
      return "Sep";
    case 9:
      return "Oct";
    case 10:
      return "Nov";
    case 11:
      return "Dec";
  }
};
