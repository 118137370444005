import { connectRouter, routerMiddleware, RouterState } from "connected-react-router";
import { createBrowserHistory } from "history";
import { AnyAction, applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk, { ThunkDispatch as OldThunkDispatch } from "redux-thunk";
import globalReducer from "./redux/Global/reducer";
import { IGlobalState } from "./redux/Global/state";
export const history = createBrowserHistory();
export type ThunkDispatch = OldThunkDispatch<IRootState, null, AnyAction>;

export interface IRootState {
  router: RouterState;
  global: IGlobalState;
}

const rootReducer = combineReducers({
  router: connectRouter(history),
  global: globalReducer,
});

// for REDUX middleware creation
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

declare global {
  /* tslint:disable:interface-name */
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk), applyMiddleware(routerMiddleware(history)))
);
