import { IGlobalActions } from "./action";
import { IGlobalState } from "./state";

export const initialState: IGlobalState = {
  isLoading: false,
  modalMessage: null,
  modalButtonText: "",
  modalFunction: null,
  modalAutoOff: 0,
  canPlaySound: null,
};

export default function globalReducer(state: IGlobalState = initialState, action: IGlobalActions) {
  switch (action.type) {
    case "@@global/setIsLoading":
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case "@@global/setMessageModal":
      return {
        ...state,
        modalMessage: action.message,
      };
    case "@@global/setMessageModalConfirmFunction":
      return {
        ...state,
        modalButtonText: action.buttonText,
        modalFunction: action.action,
      };
    case "@@global/setCanPlaySound":
      return {
        ...state,
        canPlaySound: action.canPlaySound,
      };
    case "@@global/resetMessageModal":
      return {
        ...state,
        modalMessage: initialState.modalMessage,
        modalButtonText: initialState.modalButtonText,
        modalFunction: initialState.modalFunction,
        modalAutoOff: initialState.modalAutoOff,
      };

    default:
      return state;
  }
}
