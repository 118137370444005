export const setIsLoading = (isLoading: boolean) => {
  return {
    type: "@@global/setIsLoading" as const,
    isLoading,
  };
};

export const setMessageModal = (message: string | null) => {
  return {
    type: "@@global/setMessageModal" as const,
    message,
  };
};
export const setMessageModalConfirmFunction = (buttonText: string, action: () => void) => {
  return {
    type: "@@global/setMessageModalConfirmFunction" as const,
    buttonText,
    action,
  };
};
export const resetMessageModal = () => {
  return {
    type: "@@global/resetMessageModal" as const,
  };
};
export const setCanPlaySound = (canPlaySound: boolean) => {
  return {
    type: "@@global/setCanPlaySound" as const,
    canPlaySound,
  };
};

type ActionCreators =
  | typeof setIsLoading
  | typeof setMessageModal
  | typeof resetMessageModal
  | typeof setCanPlaySound
  | typeof setMessageModalConfirmFunction;

export type IGlobalActions = ReturnType<ActionCreators>;
