import React from "react";

function Mouth() {
  return (
    <svg width="344" height="58" viewBox="0 0 344 58" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 12C134 73.6 276.167 37.6667 332 12" stroke="#2BFFA6" strokeWidth="23" strokeLinecap="round" />
    </svg>
  );
}

export default Mouth;
