import Header from "Components/Header";
import _ from "lodash";
import Mouth from "Mouth";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCanPlaySound } from "redux/Global/action";
import { IRootState } from "store";

type LookDirection = "normal" | "left" | "right" | "smile";
const ANNOUNCE_TIME = 8; //seconds
// const currentLanguage = "zh-HK";

function Home() {
  // const [speechText, setSpeechText] = useState("正在消毒，請注意");
  const [lookDirection, setLookDirection] = useState<LookDirection>("normal");
  const [smile, setSmile] = useState(false);
  const [blink, setBlink] = useState(false);
  const [intervalId, setIntervalId] = useState<any>(null);
  // const [voice, setVoice] = useState<SpeechSynthesisVoice | null>(null);
  const intervalRef = useRef<any>();
  const dispatch = useDispatch();
  const announcement = new Audio("/SterilizingCaution.mp3");
  const canPlaySound = useSelector((state: IRootState) => state.global.canPlaySound);

  const setEyeMotion = () => {
    switch (lookDirection) {
      // case "normal":
      //   return "normal ";
      case "left":
        return "lookLeft ";
      case "right":
        return "lookRight ";
      case "smile":
        return "smileEyes";
    }
  };
  const smileyFace = () => {
    setSmile(true);
    setLookDirection("smile");
  };

  useEffect(() => {
    if (lookDirection !== "smile") {
      setSmile(false);
    }
  }, [lookDirection]);

  useEffect(() => {
    if (!blink) {
      const id = setInterval(() => {
        setBlink(true);
      }, 7000);
      setIntervalId(id);
    } else {
      setTimeout(() => {
        setBlink(false);
        clearInterval(intervalId);
      }, 800);
    }
  }, [blink]);

  const getRandomArbitrary = (min: number, max: number) => {
    return Math.random() * (max - min) + min;
  };

  useEffect(() => {
    const loop = () => {
      _.sample([
        () => setLookDirection("left"),
        () => setLookDirection("right"),
        () => setLookDirection("normal"),
        smileyFace,
      ])!();
      const random = getRandomArbitrary(1500, 3000);
      setTimeout(() => {
        loop();
      }, random);
    };
    loop();
    return () => {
      dispatch(setCanPlaySound(false));
      clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    if (canPlaySound) {
      intervalRef.current = setInterval(() => {
        announcement.play();
        // speak();
      }, ANNOUNCE_TIME * 1000);
    } else {
      clearInterval(intervalRef.current);
    }
  }, [canPlaySound]);

  // const speak = () => {
  //   if (voice?.lang !== currentLanguage) {
  //     setVoices();
  //     return () => speak();
  //   }
  //   const utterance = new window.SpeechSynthesisUtterance();
  //   utterance.text = speechText;
  //   utterance.voice = voice;
  //   utterance.onend = () => {};
  //   utterance.rate = 0.7;
  //   utterance.pitch = 1.3;
  //   utterance.volume = 1.5;
  //   window.speechSynthesis.speak(utterance);
  // };

  // const setVoices = () => {
  //   const voices = window.speechSynthesis.getVoices();
  //   console.log(voices.filter((i) => i.lang === currentLanguage));
  //   const voice = voices.filter((i) => i.lang === currentLanguage)[0];
  //   setVoice(voice);
  // };

  // useEffect(() => {
  //   if (!voice) {
  //     setVoices();
  //   }
  // }, [voice]);

  return (
    <>
      {canPlaySound === null && (
        <div className="fixCenter full-size z3 flex-column-center" style={{ background: "#0008" }}>
          <div className="mb-3">Allow to play sound?</div>
          <div className="flex-center">
            <button
              className="mr-1"
              style={{ width: "80px" }}
              onClick={() => {
                dispatch(setCanPlaySound(true));
                // setVoices();
              }}
            >
              Yes
            </button>
            <button
              className="ml-1"
              style={{ width: "80px" }}
              onClick={() => {
                dispatch(setCanPlaySound(false));
                // setVoices();
              }}
            >
              No
            </button>
          </div>
        </div>
      )}
      <Header />
      {/* <Webcam className="fixed center" style={{ opacity: 0.15, height: "100vh" }} /> */}
      <div className="flex1 relative full-size">
        <div className={`absolute left eye ${setEyeMotion()} ${blink && "blink"}`}></div>
      </div>
      <div className="flex1 relative full-size">
        <div className={`absolute right eye ${setEyeMotion()} ${blink && "blink"}`}></div>
      </div>
      <div className={`absolute mouth ${smile && "smile"}`}>
        <Mouth />
      </div>
    </>
  );
}

export default Home;
