import axios from "axios";
import "CSS/Header.css";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Webcam from "react-webcam";
import { setCanPlaySound, setIsLoading } from "redux/Global/action";
import { IRootState } from "store";
import { IWeatherForecast } from "variables";
import { displayDate } from "../helpers/date";
import { Temperatures, TextRowLayout, WeatherForecast } from "./LayoutComponents";
import { getWeatherAPI, HEADER_HEIGHT, MESSAGE_TIME, weatherForecastAPI } from "./headerVariables";
import { LeftArrow, Speaker } from "./IconsOnly";
import { useNavigate } from "react-router";

interface TemperatureData {
  place: string;
  value: number;
  unit: string;
}

const displayMessages = [
  "Hi! My name is Black Ant Robot!",
  "I can tell you the weather and sterilize the area for you!",
];

function Header() {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useState(false);
  const [temperatures, setTemperatures] = useState({ max: 0, min: 0 });
  const [weatherForecast, setWeatherForecast] = useState<IWeatherForecast[]>([]);
  const [iconNumber, setIconNumber] = useState(0);
  const [recordTime, setRecordTime] = useState("");
  const [currentTime, setCurrentTime] = useState("");
  const [intervalId, setIntervalId] = useState<any>(null);
  const [currentMessageIdx, setCurrentMessageIdx] = useState(0);
  const global = useSelector((state: IRootState) => state.global);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getWeather = async () => {
    try {
      const res = await axios.get(getWeatherAPI);
      const result = await res.data;
      setIconNumber(result.icon[0]);
      const temp = result.temperature.data.map((i: TemperatureData) => i.value);
      setTemperatures({ max: Math.max(...temp), min: Math.min(...temp) });
      setRecordTime(result.temperature.recordTime);
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  const getWeatherForecast = async () => {
    try {
      const res = await axios.get(weatherForecastAPI);
      const result = await res.data;
      setWeatherForecast(result.weatherForecast.slice(0, 7));
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  useEffect(() => {
    const id = setInterval(() => {
      setCurrentTime(displayDate());
    }, 1000);
    setIntervalId(id);
    return () => clearInterval(intervalId);
  }, []);

  useLayoutEffect(() => {
    dispatch(setIsLoading(true));
    getWeather();
    getWeatherForecast();
  }, []);

  const nextMessage = () => {
    if (currentMessageIdx + 1 === scrollRef.current?.childElementCount) {
      setCurrentMessageIdx(0);
    } else {
      setCurrentMessageIdx((idx) => idx + 1);
    }
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo(0, parseInt(HEADER_HEIGHT) * currentMessageIdx);
    }
    setTimeout(() => {
      nextMessage();
    }, MESSAGE_TIME);
  }, [currentMessageIdx]);

  return (
    <section
      className="full-width flex-row-between borderBox absolute z1 headerContainer"
      style={{ height: HEADER_HEIGHT, overflowY: "visible" }}
    >
      <div className="flex-center">
        <div className="flex-center pointer relative" onClick={() => setShowMenu(!showMenu)}>
          <Webcam mirrored style={{ maxHeight: HEADER_HEIGHT, opacity: 0.8 }} />
        </div>

        <div
          className="pointer ml-3 flex-center"
          style={{ minWidth: "fit-content" }}
          onClick={() => dispatch(setCanPlaySound(!global.canPlaySound))}
        >
          <Speaker />
          <h6 className="ml-2" style={{ color: global.canPlaySound ? "#777777" : "#FFFFFF" }}>
            {global.canPlaySound ? "關閉廣播" : "開始廣播"}
          </h6>
        </div>
        {showMenu && <RouteButton text={"GO TO ADVERT PAGE"} onClick={() => navigate("/advertisement")} />}
      </div>
      {global.isLoading ? (
        <>Loading...</>
      ) : (
        <div
          ref={scrollRef}
          className="full-width borderBox flex-column-start-end textContainer"
          style={{ maxHeight: HEADER_HEIGHT }}
        >
          {displayMessages.map((i) => {
            return (
              <TextRowLayout key={i}>
                <h6>{i}</h6>
              </TextRowLayout>
            );
          })}
          <TextRowLayout>
            <>{currentTime}</>
          </TextRowLayout>
          <Temperatures max={temperatures.max} min={temperatures.min} recordTime={recordTime} iconNumber={iconNumber} />
          <WeatherForecast isBegin forecasts={weatherForecast.slice(0, 4)} />
          <WeatherForecast forecasts={weatherForecast.slice(4, 7)} />
        </div>
      )}
    </section>
  );
}

export default Header;

interface RouteButtonProps {
  text: string;
  onClick: () => void;
}

export const RouteButton = (props: RouteButtonProps) => {
  return (
    <button
      onClick={props.onClick}
      className="absolute flex-center z3 px-3 borderBox pointer"
      style={{ left: "0px", bottom: "-40px", height: "40px", borderRadius: "6px" }}
    >
      <h6>{props.text}</h6>
      <div className="flex-center ml-1" style={{ transform: "rotate(180deg)" }}>
        <LeftArrow />
      </div>
    </button>
  );
};
