import React from "react";
import { useSelector } from "react-redux";
import { IRootState } from "store";

export const Speaker = () => {
  const isActive = useSelector((state: IRootState) => state.global.canPlaySound);
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 29.918V18.08C4 16.932 4.896 16 6 16H13.172C13.4367 15.9991 13.6984 15.9446 13.9414 15.8397C14.1844 15.7349 14.4037 15.5819 14.586 15.39L20.586 8.61403C21.846 7.30203 24 8.23203 24 10.086V37.914C24 39.782 21.82 40.704 20.568 39.366L14.588 32.628C14.4052 32.4307 14.1837 32.2731 13.9373 32.165C13.691 32.057 13.425 32.0008 13.156 32H6C4.896 32 4 31.068 4 29.918Z"
        stroke={isActive ? "#777777" : "#FFFFFF"}
        strokeWidth="3.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32 17C34.666 20.556 34.666 27.444 32 31"
        stroke={isActive ? "#777777" : "#FFFFFF"}
        strokeWidth="3.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38 10C45.976 17.616 46.024 30.434 38 38"
        stroke={isActive ? "#777777" : "#FFFFFF"}
        strokeWidth="3.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const LeftArrow = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.95253 8.95323C6.10631 11.9001 4.5314 15.8471 4.567 19.9439C4.6026 24.0407 6.24586 27.9596 9.14286 30.8566C12.0399 33.7536 15.9588 35.3969 20.0556 35.4325C24.1525 35.4681 28.0994 33.8932 31.0463 31.047C33.8925 28.1001 35.4674 24.1532 35.4318 20.0563C35.3962 15.9595 33.7529 12.0406 30.8559 9.14357C27.9589 6.24657 24.04 4.6033 19.9432 4.5677C15.8463 4.5321 11.8994 6.10701 8.95253 8.95323ZM30.1713 9.82823C32.1796 11.8394 33.5467 14.4008 34.0997 17.1888C34.6527 19.9767 34.3668 22.866 33.2782 25.4915C32.1896 28.117 30.3471 30.3608 27.9836 31.9395C25.6201 33.5181 22.8416 34.3607 19.9994 34.3607C17.1572 34.3607 14.3787 33.5181 12.0152 31.9395C9.65169 30.3608 7.8092 28.117 6.72059 25.4915C5.63198 22.866 5.34612 19.9767 5.89912 17.1888C6.45213 14.4008 7.81919 11.8394 9.82753 9.82823C11.1605 8.48749 12.7454 7.4235 14.4911 6.69746C16.2368 5.97142 18.1088 5.59765 19.9994 5.59765C21.89 5.59765 23.762 5.97142 25.5077 6.69746C27.2534 7.4235 28.8383 8.48749 30.1713 9.82823ZM22.0775 13.9064C22.203 13.7969 22.3661 13.7406 22.5323 13.7494C22.6986 13.7581 22.8549 13.8312 22.9682 13.9532C23.0776 14.0787 23.1339 14.2418 23.1251 14.408C23.1164 14.5743 23.0433 14.7306 22.9213 14.8439L17.1869 20.0001L22.9213 25.1564C23.0456 25.2682 23.1204 25.4249 23.1292 25.592C23.138 25.759 23.08 25.9227 22.9682 26.047C22.8563 26.1713 22.6996 26.2461 22.5325 26.2549C22.3655 26.2637 22.2018 26.2057 22.0775 26.0939L15.8275 20.4689C15.7632 20.4089 15.7119 20.3364 15.6768 20.2557C15.6417 20.1751 15.6236 20.0881 15.6236 20.0001C15.6236 19.9122 15.6417 19.8252 15.6768 19.7445C15.7119 19.6639 15.7632 19.5913 15.8275 19.5314L22.0775 13.9064Z"
        fill="#fff"
      />
    </svg>
  );
};
